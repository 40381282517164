import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";

import { Hidden, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import { updateURL, blink } from "bild-utils";
import { NormalButton, Blink, colors, UserAvatar } from "bild-ui";
import { searchData } from "bild-data";

const useStyles = makeStyles({
  Accordion: { maxWidth: "1100px", width: "100%" },
  AccordionSummary: { cursor: "auto !important" },
  avatarContainer: {},
  expansionButtonContainer: { display: "flex", alignItems: "stretch", height: "100%" },
  expansionButton: { display: "flex", justifyContent: "center" },
  infoContainer: { margin: "0 0.5rem", userSelect: "text" },
  buttonContainer: { marginLeft: "0.5rem", textAlign: "right" },
  buttonContainerXs: { margin: "0.5rem", textAlign: "right" },
  userAdminButton: { padding: "0.5rem" },
  IDItems: { paddingRight: "1rem" },
  enrollmentContainer: { marginTop: "0.5rem" },
  masqueradeButton: { background: colors.veryLightBlue },
  detailField: { border: `1px solid ${colors.lightGray}`, paddingLeft: "0.5rem", borderRadius: "3px" },
  detailTitle: { paddingRight: "0.5rem" },
  detailValue: { background: colors.veryLightGray, padding: "0.5rem", display: "inline-block", border: "none" },
  auditButton: { background: colors.veryLightGray },
  equipButton: { background: colors.lightGray },
  equip: { color: colors.blue }
});

function UserResult({ user, expanded, isAdmin, canMasquerade }) {
  const classes = useStyles();

  const expandAll = useRef(false);
  const internalExpand = useRef(false);
  const [internalExpanded, setInternalExpanded] = useState(false);

  if (!expandAll.current && expanded) setInternalExpanded(true);
  else if (expandAll.current && !expanded && !internalExpand.current) setInternalExpanded(false);
  expandAll.current = expanded;

  function _masquerade(e) {
    e.stopPropagation();

    searchData.updateUserHistory(user);

    updateURL(blink("masquerade", { user_id: user.id }));
  }

  function _goToEnrollment(eid) {
    return e => {
      e.stopPropagation();

      searchData.updateUserHistory(user);

      updateURL(blink("enrollment", { enrollment_id: eid }));
    };
  }

  function detailField(title, value) {
    return (
      <Grid container justifyContent="center" alignItems="stretch">
        <Grid item className={classes.detailField}>
          <span className={classes.detailTitle}>{title}</span>
          <input readOnly className={classes.detailValue} value={value} />
        </Grid>
      </Grid>
    );
  }

  const enrollments = user.enrollments || [];
  const enrollmentButtons = enrollments.map((e, i) => (
    <NormalButton variant="slim" onClick={_goToEnrollment(e.id)} key={i}>
      {e.programClassAbbreviation}
    </NormalButton>
  ));

  const asAuditButton = user.enrollments && user.enrollments.length > 0 ? (
    <NormalButton variant="slim" component={Blink} dst="user-audit-antioch-courses" user_id={user.id} className={classes.auditButton}>
      <i className={`fad fa-clipboard-list`} /> &nbsp; Courses
    </NormalButton>
  ) : null;

  const equipButton = user.equip ? (
    <NormalButton variant="slim" component={Blink} dst="equip-learner" user_id={user.id} className={classes.equipButton}>
      <i className={`bfont bf-equip ${classes.equip}`} /> &nbsp; Equip
    </NormalButton>
  ) : null;

  const enrollmentDetails = enrollments.map((e, i) => (
    <Grid item container className={classes.enrollmentContainer} justifyContent="space-between" direction="row" key={i}>
      <Grid item>
        <Grid container direction="column">
          <Typography variant="subtitle1">{detailField(e.programName, e.id)}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          {detailField("LM", `${e.clUser.name} (${e.clUser.id})`)}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          {detailField("AF", `${e.afUser.name} (${e.afUser.id})`)}
        </Grid>
      </Grid>
    </Grid>
  ));

  const masqueradeButton = (
    <NormalButton variant="slim" onClick={_masquerade} className={classes.masqueradeButton}>
      Become User
    </NormalButton>
  );

  return (
    <Accordion expanded={internalExpanded} className={classes.Accordion}>
      <AccordionSummary className={classes.AccordionSummary}>
        <Grid container direction="column">
          <Grid item container alignItems="center" wrap="nowrap">
            <Grid item className={classes.avatarContainer}>
              <UserAvatar name={user.name} hideName src={user.avatarURL} srcSize="small" badgeSrc={user.badgeURL} />
            </Grid>
            <Grid item className={classes.expansionButtonContainer}>
              <NormalButton
                variant="simple"
                onClick={() => {
                  internalExpand.current = !internalExpanded;
                  setInternalExpanded(!internalExpanded);
                }}
                className={classes.expansionButton}
              >
                <i className={`fal fa-chevron-${internalExpanded ? "up" : "down"}`} />
              </NormalButton>
            </Grid>
            <Grid item xs className={classes.infoContainer}>
              <Typography variant="h5">{user.name}</Typography>
              <Typography>{user.emailAddress}</Typography>
            </Grid>
            <Hidden smDown>
              <Grid container item justifyContent="flex-end" alignItems="center" className={classes.buttonContainer}>
                {enrollmentButtons}
                {asAuditButton}
                {equipButton}
                {canMasquerade && masqueradeButton}
                {isAdmin && (
                  <Grid item>
                    <NormalButton component={Blink} dst="user-admin" user_id={user.id} className={classes.userAdminButton}>
                      <i className="fal fa-cog" />
                    </NormalButton>
                  </Grid>
                )}
                {!isAdmin && (
                  <Grid item>
                    <NormalButton component={Blink} dst="manage-user" user_id={user.id} className={classes.userAdminButton}>
                      <i className="fas fa-user-chart" />
                    </NormalButton>
                  </Grid>
                )}
              </Grid>
            </Hidden>
          </Grid>

          <Hidden smUp>
            <Grid container item justifyContent="flex-end" alignItems="center" className={classes.buttonContainerXs}>
              {enrollmentButtons}
              {asAuditButton}
              {equipButton}
              {canMasquerade && masqueradeButton}
              {isAdmin && (
                <Grid item>
                  <NormalButton component={Blink} dst="user-admin" user_id={user.id} className={classes.userAdminButton}>
                    <i className="fal fa-cog" />
                  </NormalButton>
                </Grid>
              )}
              {!isAdmin && (
                <Grid item>
                  <NormalButton component={Blink} dst="manage-user" user_id={user.id} className={classes.userAdminButton}>
                    <i className="fas fa-user-chart" />
                  </NormalButton>
                </Grid>
              )}
            </Grid>
          </Hidden>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container direction="column">
          <Grid item container>
            <Grid item className={classes.IDItems}>
              {detailField("User ID", user.id)}
            </Grid>
            <Grid item xs className={classes.IDItems}>
              {user.userNumber && detailField("Contact ID", user.userNumber)}
            </Grid>
            <Grid item>{detailField(`${user.loginCount} logins`, user.lastLoginDate)}</Grid>
          </Grid>

          {enrollmentDetails}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default UserResult;
