import { ENUMS, userData } from "bild-data";
import { checkManager, checkFundraiser, checkShowSearch } from "bild-data/user";

function buildMenuData(userOverview) {
  if (!userOverview) return { topMenuProps: {}, leftMenuSections: [] };
  const userPrefs = userData.getUserPreferences() || {};
  const path = window.location.pathname;
  const topMenuProps = { path: path, user: userOverview.user, canStaffSearch: userOverview.showStaffSearch, canSearch: userOverview.showSearch };
  const enrollmentLinks = userOverview.enrollments
    ? userOverview.enrollments
        .filter(e => e.enabled)
        .map(e => {
          return { title: e.programName, href: `/enrollments/${e.id}` };
        })
    : null;

  const leftMenuSections = [
    {
      headers: [
        {
          title: "Dashboard",
          icon: "fal fa-columns fa-fw fa-lg",
          accent: "#02458d",
          href: `/dashboard`,
          active: path === "/" || path === "/dashboard"
        },
        {
          title: "Library <span class='beta-menu-badge'>BETA</span>",
          icon: "fal fa-book fa-fw fa-lg",
          accent: "#45a94b",
          href: `/library`,
          active: path.substring(0, 8) === "/library"
        },
        {
          title: "My Folio",
          icon: "fal fa-folder-open fa-fw fa-lg",
          accent: "#ff7045",
          href: "/folio",
          active: path === "/folio"
        }
      ]
    }
  ];

  // Occasionally show the LIRN link
  if (userOverview.showLIRN) {
    leftMenuSections[0].headers.push({
      title: "LIRN",
      icon: "fal fa-books fa-fw fa-lg",
      accent: "#e4aa17",
      href: "https://proxy.lirn.net/"
    });
  }

  // Only show the programs link if the user has enrollments
  if (enrollmentLinks && enrollmentLinks.length !== 0) {
    let eActive = enrollmentLinks.some(x => path.includes(x.href));
    leftMenuSections[0].headers.push({
      title: "Programs",
      icon: "fal fa-grip-vertical fa-fw fa-lg",
      accent: "#e58700",
      links: enrollmentLinks,
      active: eActive
    });
  }

  // Show for everyone but special demo review account
  if (userOverview.user.id !== 50460) {
    // Always show the IP and translations links last
    leftMenuSections[0].headers.push(
      {
        title: "<span class='menu-title-long'>Implementation Pathway</span>",
        icon: "bfont bf-ip",
        accent: "#e4aa17",
        href: "/implement",
        active: path === "/implement"
      },
      {
        title: "BILD Translations",
        icon: "fal fa-language fa-fw fa-lg",
        accent: "#53bbb4",
        href: "/translations",
        active: path === "/translations"
      }
    );
  }

  if (checkShowSearch()) {
    // Manage All Organizations
    leftMenuSections[0].headers.push({
      title: "Equip Network",
      icon: "fal fa-chart-network fa-fw fa-lg",
      accent: "#505ad6",
      href: "/manage/all_organizations",
      active: path.substring(0, 25) === "/manage/all_organizations"
    });
  }

  if (checkManager() || userOverview.showOrganizations) {
    // Manage Organizations
    leftMenuSections[0].headers.push({
      title: "Manage Organizations",
      icon: "fal fa-cogs fa-fw fa-lg",
      accent: "#274e83",
      href: "/manage/organizations",
      active: path.substring(0, 21) === "/manage/organizations"
    });
  }

  if (checkFundraiser() && !userPrefs.demoMode) {
    // Fundraising
    leftMenuSections[0].headers.push({
      title: "Fundraising",
      icon: "fal fa-badge-dollar fa-fw fa-lg",
      accent: "#538bbb",
      href: "/fundraising",
      active: path.substring(0, 12) === "/fundraising"
    });
  }

  if (
    userOverview.showInitialReview ||
    userOverview.showValidationReview ||
    userOverview.roles.some(
      r => r.id === ENUMS.ROLES.AF_COACH.ID || r.id === ENUMS.ROLES.ADMIN.ID || r.id === ENUMS.ROLES.RTM.ID || r.id === ENUMS.ROLES.AF_TRAINEE.ID
    )
  ) {
    const secondSection = { headers: [] };

    if (userOverview.showInitialReview) {
      secondSection.headers.push({
        title: "Initial Review",
        icon: "fal fa-check fa-fw fa-lg",
        accent: "#53bbb4",
        href: "/review/initial",
        active: path.substring(0, 15) === "/review/initial",
        badge: userOverview.clAwaitingAssessmentCount || 0
      });
    }

    if (userOverview.showValidationReview) {
      secondSection.headers.push({
        title: "Validation Review",
        icon: "fal fa-check-double fa-fw fa-lg",
        accent: "#53bbb4",
        href: "/review/validation",
        active: path.substring(0, 18) === "/review/validation",
        badge: userOverview.afAwaitingAssessmentCount || 0
      });
    }

    // AF Coach
    if (userOverview.roles.some(r => r.id === ENUMS.ROLES.AF_COACH.ID)) {
      secondSection.headers.push({
        title: "Coach Review",
        icon: "fal fa-clipboard-check fa-fw fa-lg",
        accent: "#e2f516",
        href: "/review/coach",
        active: path.substring(0, 14) === "/review/coach" || path.substring(0, 15) === "/review/trainee",
        badge: userOverview.afCoachAwaitingAssessmentCount || 0
      });
      secondSection.headers.push({
        title: "Delinquent Reviews",
        icon: "fal fa-alarm-exclamation fa-fw fa-lg",
        accent: "#ec1b1b",
        href: "/review/delinquent",
        active: path.substring(0, 19) === "/review/delinquent",
        badge: userOverview.afDelinquentReviewCount || 0
      });
    }

    // AF Coach or Admin
    if (userOverview.roles.some(r => r.id === ENUMS.ROLES.AF_COACH.ID || r.id === ENUMS.ROLES.ADMIN.ID || r.id === ENUMS.ROLES.RTM.ID)) {
      secondSection.headers.push({
        title: "All Trainees",
        icon: "fal fa-clipboard-user fa-fw fa-lg",
        accent: "#ff9f29",
        href: "/review/coach/all",
        active: path.substring(0, 17) === "/review/coach/all",
        badge: 0
      });
    }

    // AF Trainee
    if (userOverview.roles.some(r => r.id === ENUMS.ROLES.AF_TRAINEE.ID)) {
      secondSection.headers.push({
        title: "Coach Reviews",
        icon: "fal fa-comment-check fa-fw fa-lg",
        accent: "#e2f516",
        href: "/review/trainee",
        active: path.substring(0, 15) === "/review/trainee",
        badge: userOverview.afTraineeRevisionCount,
        badgeClass: "primary"
      });
    }

    leftMenuSections.push(secondSection);
  }

  // Equip Menu Items
  if (userOverview.equipRoles && userOverview.equipRoles.length > 0) {
    const equipRoles = userOverview.equipRoles;
    const equipSection = {
      headers: []
    };
    if (equipRoles.some(r => r.id === ENUMS.EQUIP_ROLES.USER.ID)) {
      equipSection.headers.push({
        title: "Equip",
        icon: "bfont bf-equip",
        accent: "#9562e0",
        href: "/equip/learner",
        active: path.substring(0, 6) === "/equip" && path.substring(0, 13) !== "/equip/review"
      });
    }
    if (equipRoles.some(r => r.id === ENUMS.EQUIP_ROLES.LEADER.ID || r.id === ENUMS.EQUIP_ROLES.MENTOR.ID || r.id === ENUMS.EQUIP_ROLES.TEACHER.ID)) {
      equipSection.headers.push({
        title: "Equip Review",
        icon: "fal fa-check fa-fw fa-lg",
        accent: "#7474ee",
        href: "/equip/review",
        active: path.length === 13 && path.substring(0, 13) === "/equip/review",
        badge: userOverview.equipReviewCount || 0
      });
    }
    if (userOverview.showStaffSearch) {
      equipSection.headers.push({
        title: "Shepherds",
        icon: "fal fa-staff fa-fw fa-lg",
        accent: "#a0038f",
        href: "/equip/review/shepherds",
        active: path.length === 23 && path.substring(0, 23) === "/equip/review/shepherds",
      });
    }

    if (equipRoles.some(r => r.id === ENUMS.EQUIP_ROLES.SHARE_GROUP_USER.ID)) {
      equipSection.headers.push({
        title: "Equip Peers",
        icon: "fal fa-people-arrows fa-fw fa-lg",
        accent: "#f99040",
        href: "/equip/review/shared/paths",
        active: path.substring(0, 6) === "/equip" && path.substring(0, 20) === "/equip/review/shared"
      });
    }

    // Shepherding Tree
    if (!userOverview.isCoach && (userOverview.isMcceeParticipant || userOverview.isMcceeLeader)) {
      equipSection.headers.push({
        title: "MCCEE Overview",
        icon: "fal fa-user-chart fa-fw fa-lg fa-swap-opacity",
        accent: "#e2f516",
        href: "/equip/review/shepherding_overview",
        active: path.substring(0, 33) === "/equip/review/shepherding_coaches"
                || path.substring(0, 34) === "/equip/review/shepherding_overview"
                || (path.substring(0, 20) === "/equip/review/users/" && path.substring(path.length - 21, path.length) === "/shepherding_overview")
                || (path.substring(0, 20) === "/equip/review/users/" && path.substring(path.length - 17, path.length) === "/shepherding_tree")
      });
    }
    if (userOverview.isCoach || userOverview.isAdmin) {
      equipSection.headers.push({
        title: "MCCEE Coaches",
        icon: "fas fa-tasks-alt fa-fw fa-lg fa-swap-opacity",
        accent: "#e2f516",
        href: "/equip/review/shepherding_coaches",
        active: path.substring(0, 33) === "/equip/review/shepherding_coaches"
                || path.substring(0, 34) === "/equip/review/shepherding_overview"
                || (path.substring(0, 20) === "/equip/review/users/" && path.substring(path.length - 21, path.length) === "/shepherding_overview")
                || (path.substring(0, 20) === "/equip/review/users/" && path.substring(path.length - 17, path.length) === "/shepherding_tree")
      });
      if (!userPrefs.demoMode) {
        equipSection.headers.push({
          title: "Network Partners",
          icon: "fad fa-handshake-alt fa-fw fa-lg",
          accent: "#ff8787",
          href: "/network_partners",
          active: path.substring(0, 17) === "/network_partners"
        });
        equipSection.headers.push({
          title: "Civilizations",
          icon: "fad fa-mountains fa-fw fa-lg",
          accent: "#a85757",
          href: "/civilizations",
          active: path.substring(0, 14) === "/civilizations"
        });
      }
    }

    leftMenuSections.push(equipSection);
  }

  if (userOverview.showSimaReview || userOverview.showSimaStaff) {
    const simaSection = {
      headers: []
    };

    if (userOverview.showSimaReview) {
      simaSection.headers.push({
        title: "SIMA Review",
        icon: "fal fa-map fa-fw fa-lg",
        accent: "#b65fc7",
        href: "/sima/review",
        active: path.substring(0, 12) === "/sima/review",
        badge: userOverview.srAwaitingAssessmentCount || 0
      });
    }

    if (userOverview.showSimaStaff) {
      simaSection.headers.push({
        title: "SIMA Staff",
        icon: "fal fa-map-marked-alt fa-fw fa-lg",
        accent: "#b65fc7",
        href: "/sima/search",
        active: path.substring(0, 12) === "/sima/search"
      });
    }

    leftMenuSections.push(simaSection);
  }

  if (userOverview.user.equipReview) {
  }

  return { topMenuProps, leftMenuSections };
}

export default buildMenuData;
